<template>
  <div class="d-flex flex-wrap">
    <div
      class="px-5"
      :class="
        $vuetify.display.smAndDown || tab === 'verkauf' ? 'w-100' : 'w-60'
      "
      style="min-height: 100vh"
    >
      <v-tabs v-model="tab">
        <v-tab>Details</v-tab>
        <v-tab @click="$router.push('/property/' + id + '/edit')"
          >Bearbeiten
        </v-tab>
        <v-tab value="wert">Marktwert</v-tab>
        <v-tab value="maklervertrag">Maklervertrag</v-tab>
        <v-tab value="makler">Makler</v-tab>
        <v-tab value="dokumente">Dokumente</v-tab>
        <v-tab value="long liste">Long Liste</v-tab>
        <v-tab value="interessenten">Interessenten</v-tab>
        <v-tab value="verkauf">Verkauf</v-tab>
        <v-tab value="logbuch">Logbuch</v-tab>
      </v-tabs>
      <v-window v-model="tab" :touch="false">
        <v-window-item>
          <PropertyDetails :property-id="id" />
        </v-window-item>
        <v-window-item value="wert">
          <div class="ma-5">
            <CreateNewValuation :property-id="id" />
          </div>
          <ValuationItem :property-id="id" />
        </v-window-item>
        <v-window-item value="maklervertrag">
          <MandateContractWrapper edit :propertyId="id" />
        </v-window-item>
        <v-window-item value="makler">
          <MaklersOfPropertyView :propertyId="id" />
        </v-window-item>
        <v-window-item value="dokumente">
          <DocumentUpload :propertyId="id" />
        </v-window-item>
        <v-window-item value="long liste">
          <LongList :propertyId="id" />
        </v-window-item>

        <v-window-item value="interessenten">
          <InterestedUsersList agent :property-id="id" />
        </v-window-item>
        <v-window-item value="verkauf">
          <SaleProcessForProperty :property-id="id" />
        </v-window-item>
        <v-window-item value="logbuch">
          <LogbookItem :property-id="id" />
        </v-window-item>
      </v-window>
    </div>
    <div
      v-if="$vuetify.display.mdAndUp"
      v-show="tab !== 'verkauf'"
      class="w-40"
      style="min-height: 100vh"
    >
      <MapComponent :no-geolocate="true" v-model="location" />
    </div>
  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import PropertyDetails from "@/components/property/details/PropertyDetails";
import DocumentUpload from "@/components/property/documents/DocumentUpload";
import InterestedUsersList from "@/components/interest/InterestedUsersList";
import SaleProcessForProperty from "@/views/agent/SaleProcessForProperty.vue";
import MandateContractWrapper from "@/components/property/contract/MandateContractWrapper.vue";
import MaklersOfPropertyView from "@/views/agent/MaklersOfPropertyView.vue";
import LongList from "@/views/agent/LongList.vue";
import { getPointOfProperty } from "@/services/property.service";
import { useMapStore } from "@/stores/modules/map.module";
import CreateNewValuation from "@/components/property/valuation/CreateNewValuation.vue";
import ValuationItem from "@/components/property/valuation/ValuationItem.vue";
import LogbookItem from "@/components/logbook/LogbookItem.vue";
export default {
  name: "AgentPropertyDetails",
  setup() {
    const mapStore = useMapStore();
    return { mapStore };
  },
  components: {
    LogbookItem,
    ValuationItem,
    CreateNewValuation,
    MaklersOfPropertyView,
    MandateContractWrapper,
    SaleProcessForProperty,
    InterestedUsersList,
    PropertyDetails,
    DocumentUpload,
    MapComponent,
    LongList,
  },
  props: {
    id: String,
  },
  data() {
    return {
      point: null,
      tab: 0,
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 1,
      },
    };
  },
  computed: {
    map() {
      return this.mapStore.map;
    },
  },
  watch: {
    map: {
      handler() {
        console.log("Map changed", this.map);
        this.flyToProperty();
      },
      immediate: true,
    },
  },
  methods: {
    async flyToProperty() {
      if (!this.mapStore.hasMap) {
        console.log("No map found");
        return;
      }
      if (!this.point) this.point = await getPointOfProperty(this.id);
      console.log("Flying to point: ", this.point);
      this.mapStore.flyToPoint(this.point);
    },
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>

<style scoped>
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
</style>
