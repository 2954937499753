<template>
  <v-sheet
    @click="$router.push('/property/' + property.id)"
    class="hvr-shrink"
    rounded
    style="position: relative; overflow: hidden"
  >
    <v-row style="position: relative" class="no-margin">
      <v-col
        class="pa-5"
        style="
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
        "
        cols="10"
        sm="6"
      >
        <small
          :class="
            $vuetify.display.smAndUp ? 'building-type' : 'building-type-mobile'
          "
          v-text="propertyType"
        ></small>
        <div class="text-left">
          <h3 class="mb-3">
            <span class="mr-1" v-text="property.label"></span>
          </h3>
          <small>
            <span v-text="address?.street"></span> <br />
            <span v-text="address?.zipCode"></span>
            <span class="ml-1" v-text="address?.city"></span><br />

            <span class="text-disabled"
              ><span v-text="property.distance + ' km'"> </span> entfernt</span
            ><br />
            <span v-if="showSaved"
              ><span></span><span v-text="property?.savedCount"></span
              ><span>x gespeichert</span></span
            >
          </small>
          <v-chip-group>
            <v-chip v-for="role of property.myRoles" size="small" :key="role.id"
              ><EnumDisplay
                :enum-object="relationsEnum"
                :value="role.relationType"
            /></v-chip>
          </v-chip-group>
        </div>
        <div class="text-left">
          <p class="text-disabled">Marktwert</p>
          <p
            style="color: rgba(var(--v-theme-accent)); font-weight: bold"
            v-if="property.saleGrossPrice"
          >
            <CurrencyDisplay :number="property.saleGrossPrice" />
          </p>
          <p v-else>-</p>
          <span v-if="canCreateOffer">
            <v-btn
              size="small"
              color="accent"
              v-if="property.offerOfUser"
              @click.stop="
                $router.push({
                  path: '/property/' + property.id,
                  query: { tab: 'angebot' },
                })
              "
              >Angebot ansehen</v-btn
            >
            <CreateOfferDialog :property-id="property.id" v-else />
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        style="position: relative; max-height: 300px"
        class="pa-0 mt-12 rounded-e mt-sm-0 d-flex align-center"
      >
        <PropertyThumbnail :property-id="property.id" />
      </v-col>
    </v-row>
    <div
      class="pa-1 d-flex flex-column"
      style="position: absolute; right: 0; top: 0; gap: 5px"
      cols="1"
    >
      <SavePropertyButton
        v-model="saved"
        :property-id="property.id"
        :user-property-relations="property.myRoles"
        icon
      >
      </SavePropertyButton>
      <EditPropertyButton :property-id="property.id"> </EditPropertyButton>

      <PropertyFilesPopup :property="property">
        <template #activator="props">
          <v-btn
            v-tooltip:top="{
              text: 'Dokumente',
              openDelay: 1000,
            }"
            color="accent"
            size="small"
            v-bind="props"
            icon
            tile
            rounded
          >
            <v-icon size="small">mdi-file-document-multiple-outline</v-icon>
          </v-btn>
        </template>
      </PropertyFilesPopup>

      <ShowOnMapButton :property="property" />
    </div>
  </v-sheet>
</template>

<script>
import EPropertyRelationType from "@/components/enums/EPropertyRelationType";
import SavePropertyButton from "@/components/user/SavePropertyButton.vue";
import ShowOnMapButton from "@/components/property/ShowOnMapButton.vue";
import { useMapStore } from "@/stores/modules/map.module";
import PropertyFilesPopup from "@/components/property/PropertyFilesPopup.vue";
import PropertyThumbnail from "@/components/property/PropertyThumbnail.vue";
import EditPropertyButton from "@/components/property/EditPropertyButton.vue";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import CreateOfferDialog from "@/components/property/offer/CreateOfferDialog.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";

export default {
  name: "PropertyItemUser",
  components: {
    EnumDisplay,
    CreateOfferDialog,
    CurrencyDisplay,
    EditPropertyButton,
    PropertyThumbnail,
    PropertyFilesPopup,
    ShowOnMapButton,
    SavePropertyButton,
  },
  props: {
    showSaved: Boolean,
    canCreateOffer: Boolean,
    property: Object,
  },
  setup() {
    const mapStore = useMapStore();
    return {
      mapStore,
    };
  },
  created() {},
  data() {
    return {
      relationsEnum: EPropertyRelationType,
      saved: false,
    };
  },
  methods: {},
  computed: {
    propertyType() {
      switch (this.property.propertyType) {
        case "BUILDING":
          return "Gebäude";
        case "UNIT":
          return "Einheit";
        case "Land":
          return "Grundstück";
        default:
          return this.property.propertyType;
      }
    },
    userLocation() {
      return this.mapStore.userPosition;
    },
    address() {
      return this.property.address;
    },
    marker() {
      return this.address?.mapMarker;
    },
  },
};
</script>

<style scoped>
.building-type {
  position: absolute;
  top: 50%;
  right: 0;
  color: white;
  transform: translateY(-50%) translateX(25%) rotate(270deg);
  font-size: 0.7rem;
  letter-spacing: 3px;
  color: lightgrey;
}
.building-type-mobile {
  position: absolute;
  bottom: 0;
  right: 43%;
  color: white;
  font-size: 0.7rem;
  letter-spacing: 3px;
  color: lightgrey;
  margin: -45px;
}
.no-margin {
  margin: 0 !important;
}
</style>
