<template>
  <div>
    <v-sheet v-if="info" rounded class="my-5 pa-5 black lighten-2">
      <div class="d-flex flex-wrap align-center text-left mb-7">
        <h2 class="mr-2">
          <span>{{ info.propertyType }} {{ propertyId }}</span
          ><span v-if="info.label"> - {{ info.label }}</span
          >
        </h2>
        <v-chip-group v-if="info.chips">
          <v-chip v-for="chip of info.chips" :key="chip">{{ chip }}</v-chip>
        </v-chip-group>
      </div>
      <v-divider
        class="border-opacity-100 mb-2"
        thickness="1"
        color="accent"
      ></v-divider>
      <v-row>
        <v-col
          v-if="info.block1"
          class="d-flex flex-wrap py-5 justify-space-evenly"
          style="width: 100%"
        >
          <v-col
            class="text-center"
            v-for="section of info.block1"
            :key="section.label"
            cols="6"
            sm="3"
            md="6"
            lg="3"
          >
            <p
              class="font-weight-bold"
              style="font-size: 1.15rem; white-space: nowrap"
            >
              {{ section.label }}
            </p>
            <p class="text-disabled" style="font-size: 0.9rem">
              {{ section.data }}
            </p>
          </v-col>
        </v-col>
      </v-row>
      <v-divider
        class="border-opacity-100 mt-2"
        thickness="1"
        color="accent"
      ></v-divider>

      <v-row
        v-if="info.block2"
        class="my-5 no-gutters text-left text-sm-center d-flex justify-start"
      >
        <v-col
          cols="12"
          sm="4"
          v-for="section of info.block2"
          :key="section.label"
          :order="$vuetify.display.smAndDown ? '1' : ''"
          style="overflow-x: hidden"
        >
          <p class="text-disabled" v-text="section.data"></p>
          <p class="text-truncate" v-text="section.label"></p>
        </v-col>
      </v-row>
    </v-sheet>
    <div>
      <h2 class="text-left">Galerie  <v-btn @click="$router.push('/property/'+this.propertyId+'/edit/gallery')" size="small" variant="outlined">Fotos hinzufügen</v-btn></h2>
      <PropertyGalery :property-id="propertyId" />
    </div>
  </div>
</template>

<script>
// import PropertyProperty from "@/components/property/details/PropertyProperty.vue";
import PropertyGalery from "@/components/property/details/PropertyGalery";
import { getPropertySmallInfo } from "@/services/property.service";
import EUsageType from "@/components/enums/EUsageType";
export default {
  name: "PropertyDetails",
  data() {
    return {
      info: null,
      usageType: EUsageType,
    };
  },
  props: {
    propertyId: String,
  },
  components: { PropertyGalery },
  methods: {
    async getInfo() {
      this.info = await getPropertySmallInfo(this.propertyId);
      console.log("Info: ", this.info);
    },
  },
  computed: {
    propertyType() {
      switch (this.info.block2[0].label) {
        case "BUILDING":
          return "Gebäude";
        case "UNIT":
          return "Einheit";
        case "Land":
          return "Grundstück";
        default:
          return this.property.propertyType;
      }
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style scoped></style>
