<template>
  <div class="pa-5">
    <v-row>
      <v-col class="text-left" cols="12" sm="6">
        <h1 style="font-size: 2rem">Objekt</h1>
        <h3>Typ: Gebäude</h3>
        <div class="mt-5 mb-3">
          <h2>Adresse</h2>
          <div v-text="building.address"></div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="mt-5">
        <div class="pa-5" v-html="svg"></div>
      </v-col>
    </v-row>
    <v-form
      @submit.prevent="create"
      ref="form"
      class="d-flex flex-wrap"
      style="gap: 15px"
    >
      <v-text-field style="width: 100%" v-model="building.label" label="Titel">
      </v-text-field>
      <SelectEnum
        label="Haupt-Nutzungsart"
        style="width: 100%"
        v-model="building.usageType"
        :data="usageTypes"
      />
      <SelectEnum
        label="Zweit-Nutzungsart"
        style="width: 100%"
        v-model="building.useTypeSecondary"
        :data="usageTypes"
      />
      <KreaNumberInput v-model="building.plotArea" label="Grundstücksgröße">
        <template #append-inner><span class="text-grey">m²</span></template>
      </KreaNumberInput>

      <KreaNumberInput v-model="building.usableArea" label="Nutzfläche">
        <template #append-inner><span class="text-grey">m²</span></template>
      </KreaNumberInput>
      <div style="width: 100%; gap: 15px" class="d-flex">
        <v-text-field
          style="width: 50%"
          v-model="building.constructionYear"
          label="Baujahr"
          outlined
          hint='muss in "YYYY" Format sein'
          maxlength="4"
          type="number"
        ></v-text-field>
        <v-text-field
          style="width: 50%"
          v-model="building.lastRefurbishmentYear"
          label="Letzte Modernisierung"
          outlined
          hint='muss in "YYYY" Format sein'
          maxlength="4"
          type="number"
        ></v-text-field>
      </div>
      <div style="width: 100%" class="d-flex justify-space-between">
        <v-switch color="accent" v-model="building.rented" label="vermietet" />
        <v-switch
          color="accent"
          v-if="!building.rented"
          v-model="building.ownerOccupation"
          label="In eigener Nutzung"
        />
      </div>

      <div style="width: 100%" v-if="building.rented">
        <KreaNumberInput
          v-model="building.grossPotentialIncome"
          label="jählriche Mieteinnahmen"
        >
          <template #append-inner>
            <span class="text-grey">€</span>
          </template>
        </KreaNumberInput>
      </div>

      <div style="width: 100%; gap: 15px" class="d-flex">
        <KreaNumberInput
          v-model="building.saleGrossPrice"
          style="width: 60%"
          label="Kaufpreis"
          :rules="[
            (v) => !!v || 'Eingabe ist erforderlich',
            (v) => !isNaN(parseFloat(v)) || 'Nur Zahlen sind erlaubt',
          ]"
        >
          <template #append-inner>
            <span class="text-grey">€</span>
          </template>
        </KreaNumberInput>
      </div>
      <v-btn block size="large" type="submit" color="accent"
        >Objekt anlegen</v-btn
      >
      <v-btn
        block
        @click="$emit('close')"
        color="grey-darken-3"
        text="Abbrechen"
      ></v-btn>
    </v-form>
    <v-dialog v-model="showDialog" persistent max-width="400px">
      <template v-slot:activator="{ props: activatorProps }">
        <slot name="activator" :activator="activatorProps"> </slot>
      </template>
      <v-card class="pa-3">
        <v-card-title class="text-center">
          <v-icon size="small" class="mr-1">mdi-check</v-icon>
          Immobilie erfolgreich angelegt
        </v-card-title>
        <v-card-text>
          <p>
            Die Immobilie wurde erfolgreich angelegt. Möchten Sie weitere Details
            hinzufügen oder zurück zur Übersicht?
          </p>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn
            class="ma-0"
            size="large"
            variant="flat"
            block
            append-icon="mdi-arrow-right"
            @click="$router.push(`/property/${createdBuildingId}/edit/basic`)"
            color="accent"
            >Details hinzufügen</v-btn
          >
          <v-btn
            block
            class="bg-grey-darken-3 ma-0 mt-2"
            @click="
              showDialog = false;
              $emit('success');
            "
            >Zurück zur Übersicht</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAddressFromLatLng } from "@/services/map.service";
import { createBuilding } from "@/services/property.service";
import { useMapStore } from "@/stores/modules/map.module";
import createBuildingRequest from "@/models/createBuildingRequest";
import MapMeta from "@/models/MapMeta";
import KreaNumberInput from "@/components/KreaNumberInput";
import EUsageType from "@/components/enums/EUsageType";
import SelectEnum from "@/components/form/SelectEnum";
import { useNotificationStore } from "@/stores/modules/notification.module";

//TODO Ende des mietvertrages muss an der mieteinheit hängen. Also der Value errechnet sich aus dem Mietlänge in relation zu den gemeiteten Quadratmetern
export default {
  name: "CreateBuilding",
  components: { SelectEnum, KreaNumberInput },
  emits: ["success", "close"],
  props: {
    property: null,
    clickEvent: null,
  },
  setup() {
    const mapStore = useMapStore();
    const notificationStore = useNotificationStore();

    return {
      mapStore,
      notificationStore,
    };
  },
  data() {
    return {
      showDialog: false,
      createdBuildingId: null,
      usageTypes: EUsageType,
      date: null,
      svg: null,
      building: new createBuildingRequest({}),
    };
  },
  watch: {
    property(newVal) {
      this.select(newVal, this.clickEvent);
    },
  },
  mounted() {
    this.select(this.property, this.clickEvent);
    console.log("CreateBuilding Property", this.property);
    console.log("CreateBuilding ClickEvent", this.clickEvent);
  },
  methods: {
    async select(property, clickEvent) {
      const drawService = require("@/services/draw.service");
      let address = await getAddressFromLatLng(clickEvent.lngLat);
      let mapMeta = null;
      if (property.geometry.type === "Polygon") {
        mapMeta = new MapMeta({
          height: this.property.properties.height,
          minHeight: this.property.properties.min_height,
          buildingId: this.property.id,
        });
      }
      this.building = new createBuildingRequest({
        location: property.geometry,
        buildingId: property.id,
        address: address,
        mapMeta: mapMeta,
      });
      if (property.geometry.type === "Polygon") {
        this.svg = drawService.generateSVGFromCoordinates(
          property.geometry.coordinates,
          200
        );
      }
    },
    async validateForm() {
      if (!this.$refs.form) return false;
      let response = await this.$refs.form.validate();
      return response.valid;
    },
    async create() {
      if (await this.validateForm()) {
        let building = JSON.parse(JSON.stringify(this.building));
        try {
          let data = await createBuilding(building);
          try {
            let mapJson = JSON.parse(data.mapJson);
            this.mapStore.updateData(mapJson);
          } catch (error) {
            console.log("Keine Map gefunden");
          }
          this.createdBuildingId = data.building.id;
          this.showDialog = true;
        } catch (error) {
          this.notificationStore.pushError("Ein Fehler ist aufgetreten", error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
